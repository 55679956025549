import PropTypes from 'prop-types';
import React from 'react';

import Author from 'components/shared/author';
import CategoryLabel from 'components/shared/category-label';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const Hero = ({ title, authorName, categories, readingTime }) => (
  <section className="mt-5 md:mt-16 header-offset">
    <Container>
      <div className="relative max-w-[756px] mx-auto">
        {categories && (
          <div className="flex flex-wrap mt-6 gap-x-1.5 gap-y-1.5 mb-5">
            {categories.map(({ acf: { color }, name, slug }, index) => (
              <CategoryLabel name={name} theme={color} url={`/blog/${slug}`} key={index} />
            ))}
          </div>
        )}
        <Heading size="lg" tag="h1" fontWeight="extra">
          {title}
        </Heading>
        <Author className="mt-5" name={authorName} readingTime={readingTime} />
      </div>
    </Container>
  </section>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ),
  readingTime: PropTypes.number.isRequired,
};

Hero.defaultProps = {
  categories: null,
};

export default Hero;
