import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import Author from 'components/shared/author';
import CategoryLabel from 'components/shared/category-label';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';

const ArticleCard = (props) => {
  const {
    title,
    acf: { image: imageObj, description } = {},
    categories,
    url,
    author: { node: { name: authorName } = {} } = {},
    readingTime,
    withoutAuthor,
  } = props;

  const image = imageObj?.localFile?.childImageSharp ?? '';

  return (
    <article className="group">
      <Link to={url}>
        <div className="sm:h-[190px] md:h-[195px] lg:h-[250px] xl:h-[180px] 2xl:h-[220px] overflow-hidden">
          <GatsbyImage
            className="h-full w-full"
            imgClassName="rounded-4xl"
            image={getImage(image)}
            alt={imageObj?.altText}
          />
        </div>
        {categories && (
          <div className="flex flex-wrap mt-6 gap-x-1.5 gap-y-1.5">
            {categories.nodes.map(({ acf: { color }, name }, index) => (
              <CategoryLabel name={name} theme={color} key={index} />
            ))}
          </div>
        )}
        <Heading
          className="transition-colors duration-200 xl:!leading-8 mt-4 group-hover:text-primary-2"
          tag="h2"
          size="sm"
          fontWeight="bold"
        >
          {title}
        </Heading>
        <p className="text-sm xl:text-xl xl:!leading-7 mt-2 lg:mt-4">{description}</p>
        <Author
          className="mt-2 lg:mt-4"
          name={authorName}
          readingTime={readingTime}
          withoutAuthor={withoutAuthor}
        />
      </Link>
    </article>
  );
};

ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  acf: PropTypes.shape({
    image: PropTypes.objectOf(PropTypes.any).isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
  categories: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        acf: PropTypes.shape({
          color: PropTypes.string,
        }).isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }),
  author: PropTypes.shape({
    node: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  readingTime: PropTypes.number.isRequired,
  withoutAuthor: PropTypes.bool,
};

ArticleCard.defaultPropTypes = {
  categories: null,
  withoutAuthor: false,
};

export default ArticleCard;
