import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import ArticleCard from 'components/shared/article-card';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const title = 'Latest articles';

const LatestArticles = () => {
  const {
    allWpPost: { edges: items },
  } = useStaticQuery(
    graphql`
      query {
        allWpPost(
          limit: 3
          sort: { fields: date, order: DESC }
          filter: { acf: { private: { eq: null } } }
        ) {
          edges {
            node {
              title
              readingTime
              author {
                node {
                  name
                }
              }
              acf {
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
                description
              }
              url: uri
            }
          }
        }
      }
    `
  );

  return (
    <section className="my-28 lg:my-32 xl:my-40">
      <Container>
        <div className="grid grid-cols-12 xl:gap-x-10">
          <div className="relative col-start-1 col-end-13 lg:col-start-2 lg:col-end-12">
            <Heading className="text-2xl md:text-3xl xl:text-4xl uppercase" tag="h2">
              {title}
            </Heading>
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 sm:gap-x-6 md:gap-x-8 2xl:gap-x-11 gap-y-11 md:gap-y-16 mt-10 lg:mt-16">
              {items.map(({ node }, index) => (
                <ArticleCard {...node} key={index} withoutAuthor />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default LatestArticles;
