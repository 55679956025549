import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Subscribe from 'components/shared/subscribe';

const Content = ({ content }) => (
  <>
    <article className="relative mt-10 md:mt-16">
      <Container>
        <div className="relative max-w-[756px] mx-auto">
          <div className="content">{content}</div>
        </div>
      </Container>
    </article>
    <Subscribe />
  </>
);

Content.propTypes = {
  content: PropTypes.string,
};

Content.defaultProps = {
  content: null,
};

export default Content;
