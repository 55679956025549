import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'components/shared/link';

const commonClassNames =
  'inline-block text-white font-semibold text-sm rounded-[50px] py-2 leading-none px-3.5';

const themeClassNames = {
  blue: 'bg-label-blue',
  green: 'bg-label-green',
  pink: 'bg-label-pink',
  lightBrown: 'bg-label-light-brown',
  brown: 'bg-label-brown',
  red: 'bg-label-red',
  lightBlue: 'bg-label-light-blue',
  purple: 'bg-label-purple',
  plum: 'bg-label-plum',
  olive: 'bg-label-olive',
};

const CategoryLabel = ({ name, url, theme }) => {
  const Tag = url ? Link : 'span';

  return (
    <Tag className={classNames(commonClassNames, themeClassNames[theme])} to={url}>
      {name}
    </Tag>
  );
};

CategoryLabel.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(themeClassNames)),
};

CategoryLabel.defaultProps = {
  url: null,
  theme: 'blue',
};

export default CategoryLabel;
