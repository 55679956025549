import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Author = ({
  className: additionalClassName,
  name,
  readingTime: time,
  theme,
  withoutAuthor,
}) => {
  const readingTime = `${time} min read`;
  const isThemeWhite = theme === 'white';
  const isThemeBlack = theme === 'black';

  return (
    <div
      className={classNames('flex font-semibold text-sm xl:text-base', additionalClassName, {
        'text-white': isThemeWhite,
      })}
    >
      {!withoutAuthor && (
        <span
          className={classNames(
            'flex items-center after:inline-block after:w-1 after:h-1 after:rounded-7xl after:ml-2.5 after:mr-2.5',
            { 'after:bg-white': isThemeWhite },
            { 'after:bg-black': isThemeBlack }
          )}
        >
          {name}
        </span>
      )}

      <span>{readingTime}</span>
    </div>
  );
};

Author.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readingTime: PropTypes.number.isRequired,
  theme: PropTypes.oneOf(['black', 'white']),
  withoutAuthor: PropTypes.bool,
};

Author.defaultProps = {
  theme: 'black',
  withoutAuthor: false,
};

export default Author;
